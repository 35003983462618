import request from '../../request/index'
import sevice from '@/sevice/index'
let api = {
    banner:(data:any)=>{
        return request.get(sevice.banner+data)
    },
    temps:()=>{
        return request.get(sevice.temps)
    }
}
export default  api 