interface urlType {
    course: string,
    teacher: string,
    progList: string,
    reaList: string,
    courseInfo: string,
    teacherInfo: string,
    reservation: string,
    growthBanner: string
    camptow: string,
    camplist: string,
    campInfo: string,
    siginUp: string,
    levelList: string,
    courseLst: string,
    levelSelf: string,
    staffInfo: string,
    statistics: string,
    footer: string,
    timeLine: string,
    studyLst: string,
    exam: string,
    submit: string,
    resetInfo: string,
    updatePwd: string,
    real_log: string,
    uploadImg: string,
    upHead: string,
    progTagList: string,
    programmeList: string,
    progBanner: string,
    progRammeInfo: string,
    progRammeClick: string,
    realisticList: string,
    realisticDetails: string,
    realisticstatus: string,
    specialList: string,
    specialDetails: string,
    specialInfoList: string,
    commBanner: string,
    commType: string,
    commList: string,
    commRec: string,
    commDetails: string,
    commDz: string,
    clickVideo: string,
    mallList: string,
    mallEx: string,
    exLog: string,
    accountCheck: string,
    bindAccount: string,
    getAccountInfo: string,
    progContribute: string,
    coverList: string,
    submission: string,
    subLog: string,
    withSub: string,
    againSub: string,
    checkSubScore: string,
    myProg: string,
    myRea: string,
    getVideoToken: string,
    refreshUploadVideo: string,
    getVideoInfo: string,
    delVideo: string,
    realisticSub: string,
    reaSubLog: string,
    delRea: string,
    searchComm: string,
    subProgInfo: string,
    updaSubProg: string,
    progPl: string,
    delProgPl: string,
    reaPl: string,
    progPlList: string,
    reaPlList: string,
    delReaPl: string,
    currPlList: string,
    currPl: string,
    delCurrPl: string,
    noticePlList: string,
    noticePl: string,
    delNoticePl: string,
    progNotice: string,
    checkAcc: string,
    proLabel: string,
    heatProduct: string
    proDetail: string,
    proTopic: string,
    proTopicDetail: string,
    proTopicFilter: string,
    proFilter: string,
    designMatch: string,
    matchInfo: string,
    matchSub: string,
    myWorks: string,
    allworks: string,
    worksDetail: string,
    updateWork: string,
    awardWorks: string,
    matchVote: string,
    worksInfo: string,
    matchPlList: string,
    matchPl: string,
    delMatchPl: string,
    matchReaList:string,
    matchReaDetail:string,
    matchSubRea:string,
    myMatchRea:string,
    delMatchRea:string

}
const url: urlType = {
    // 课程列表
    course: 'api/webApi/course/courseLst',
    // 讲师列表
    teacher: 'api/webApi/lecturer/lst',
    // 方案列表
    progList: 'api/webApi/index/programme_lst',
    // 实景列表
    reaList: 'api/webApi/index/realistic_lst',
    // 课程详情
    courseInfo: 'api/webApi/course/info',
    // 讲师详情
    teacherInfo: 'api/webApi/lecturer/info/',
    // 预约讲师
    reservation: 'api/webApi/lecturer/reservation',
    // 成长中心banner
    growthBanner: 'api/webApi/growth/banner',
    // 集训营2个
    camptow: "api/webApi/growth/campLst",
    // 集训营列表
    camplist: 'api/webApi/camp/lst',
    // 集训营详情
    campInfo: 'api/webApi/camp/info/',
    // 集训营报名
    siginUp: 'api/webApi/camp/siginUp/',
    // 成长等级列表
    levelList: 'api/webApi/growth/levelLst',
    // 成长等级课程
    courseLst: 'api/webApi/growth/courseLst/',
    // 当前登录人等级
    levelSelf: 'api/webApi/growth/levelSelf',
    // 用户信息
    staffInfo: 'api/webApi/user/info',
    // 统计视频观看时间
    statistics: 'api/webApi/video/statistics',
    // 网站footer
    footer: 'api/webApi/index/footer',
    // 课程预告列表
    timeLine: 'api/webApi/index/timeLine/?limit=',
    // 学习记录
    studyLst: 'api/webApi/user/studyLst',
    // 试卷历史
    exam: "api/webApi/exam/info?id=",
    // 提交试卷
    submit: 'api/webApi/exam/submit',
    // 试卷详情
    resetInfo: "api/webApi/exam/resetInfo?id=",
    // 修改密码
    updatePwd: 'api/webApi/user/up_pwd',
    // 成长值/金币--记录
    real_log: 'api/webApi/user/real_log',
    // 上传头像 oss
    uploadImg: 'api/adminApi/uploadImg',
    // 修改头像
    upHead: 'api/webApi/user/up_head',
    // 方案标签
    progTagList: 'api/webApi/programme/tag_lst',
    // 方案列表
    programmeList: 'api/webApi/programme/lst',
    // 投稿方案列表
    progContribute: 'api/webApi/sub/fLst',
    // 方案投稿封面列表
    coverList: 'api/webApi/sub/cover',
    // 方案投稿
    submission: 'api/webApi/sub/sub',
    // 方案banner
    progBanner: 'api/webApi/programme/recommend',
    // 方案详情
    progRammeInfo: 'api/webApi/programme/info?id=',
    // 方案点赞收藏
    progRammeClick: 'api/webApi/programme/click',
    // 实景图列表
    realisticList: "api/webApi/realistic/lst",
    // 实景图详情
    realisticDetails: "api/webApi/realistic/info?id=",
    // 实景图点赞
    realisticstatus: "api/webApi/realistic/click",
    // 方案专题
    specialList: 'api/webApi/programme_group/lst',
    // 方案专题详情
    specialDetails: 'api/webApi/programme_group/info?id=',
    // 方案专题详情列表
    specialInfoList: 'api/webApi/programme_group/programme_lst',
    // 论坛banner
    commBanner: 'api/webApi/an/banner',
    // 论坛分类
    commType: 'api/webApi/an/cate_lst',
    // 论坛列表
    commList: 'api/webApi/an/lst',
    // 论坛推荐
    commRec: 'api/webApi/an/hot?id=',
    // 帖子详情
    commDetails: 'api/webApi/an/info',
    // 帖子点赞
    commDz: 'api/webApi/an/click',
    clickVideo: 'api/webApi/course/clickVideo?id=',
    // 商品列表
    mallList: 'api/webApi/mall/lst',
    // 商品兑换
    mallEx: 'api/webApi/mall/exchange',
    // 兑换记录
    exLog: 'api/webApi/mall/ex_log?page=',
    // 查询酷家乐账号
    accountCheck: 'api/webApi/user/s_user',
    // 酷家乐账号绑定
    bindAccount: 'api/webApi/user/s_bd',
    // 获取酷家乐账号信息
    getAccountInfo: 'api/webApi/user/bd_info',
    // 方案投稿记录
    subLog: 'api/webApi/sub/u_s_log',
    // 实景图投稿记录
    reaSubLog: 'api/webApi/sub/sjt',
    // 撤回投稿
    withSub: 'api/webApi/sub/u_w',
    // 重新投稿
    againSub: 'api/webApi/sub/u_a',
    // 查看评分
    checkSubScore: 'api/webApi/sub/ss',
    // 我的优秀方案
    myProg: 'api/webApi/sub/my_pro',
    // 我的实景图
    myRea: 'api/webApi/sub/my_rel',
    // 获取视频上传凭证
    getVideoToken: 'api/webApi/sub/getVideoToken',
    // 刷新视频上传凭证
    refreshUploadVideo: 'api/webApi/sub/refreshUploadVideo',
    // 获取视频连接
    getVideoInfo: 'api/webApi/sub/getVideoInfo',
    // 删除视频
    delVideo: 'api/webApi/sub/d_v',
    // 实景图投稿
    realisticSub: 'api/webApi/sub/r_c',
    // 实景图取消投稿
    delRea: 'api/webApi/sub/quxiao',
    // 投稿小区选择
    searchComm: 'api/webApi/sub/xiaoqu',
    // 投稿方案详情
    subProgInfo: "api/webApi/sub/sub_pro_info",
    // 方案重新投稿
    updaSubProg: 'api/webApi/sub/u_a',
    // 方案评论
    progPl: 'api/webApi/pl/c_pc',
    // 删除方案评论
    delProgPl: 'api/webApi/pl/delpc',
    // 实景图评论
    reaPl: 'api/webApi/pl/cr',
    // 方案评论列表
    progPlList: 'api/webApi/pl/p_c_lst',
    // 实景图评论列表
    reaPlList: 'api/webApi/pl/r_lst',
    // 删除实景图评论
    delReaPl: 'api/webApi/pl/delr',
    // 课程评论列表
    currPlList: 'api/webApi/pl/cou_Lst',
    // 课程评论
    currPl: 'api/webApi/pl/ck',
    // 删除课程评论
    delCurrPl: 'api/webApi/pl/delkc',
    // 互动中心评论列表
    noticePlList: 'api/webApi/pl/a_c_lst',
    // 互动中心评论
    noticePl: 'api/webApi/pl/c_ac',
    // 删除互动中心评论
    delNoticePl: 'api/webApi/pl/delac',
    // 投稿须知
    progNotice: 'api/webApi/sub/subAn',
    // 检测是否关联酷家乐账号
    checkAcc: 'api/webApi/sub/is_bd',
    // 产品中心标签
    proLabel: 'api/webApi/product/cate',
    // 产品中心：热门产品
    heatProduct: 'api/webApi/product/h_lst',
    // 产品详情
    proDetail: 'api/webApi/product/info',
    // 产品中心：产品专题
    proTopic: 'api/webApi/product/g_lst',
    // 产品中心：产品专题详情
    proTopicDetail: 'api/webApi/product/g_info',
    // 产品中心：专题筛选
    proTopicFilter: 'api/webApi/product/pg_lst',
    // 产品中心：产品筛选
    proFilter: 'api/webApi/product/p_lst',
    // 大赛列表
    designMatch: 'api/webApi/ds/l',
    // 大赛详情
    matchInfo: 'api/webApi/ds/i/',
    // 大赛报名
    matchSub: 'api/webApi/ds/sub_pro',
    // 我的作品
    myWorks: 'api/webApi/ds/my_pro',
    // 全部作品
    allworks: 'api/webApi/ds/pro_lst',
    // 作品详情
    worksDetail: 'api/webApi/ds/sub_pro_info',
    // 修改作品
    updateWork: 'api/webApi/ds/again_sub',
    // 获奖作品
    awardWorks: 'api/webApi/ds/publish_lst',
    // 作品投票
    matchVote: 'api/webApi/ds/vote',
    // 作品详情
    worksInfo: 'api/webApi/ds/pro_info',
    // 大赛作品评论列表
    matchPlList: 'api/webApi/ds/pl_l',
    // 大赛作品评论
    matchPl: 'api/webApi/ds/pl_c',
    // 删除大赛作品评论
    delMatchPl: 'api/webApi/ds/pl_d',
    // 征集实景图列表
    matchReaList:'api/webApi/ds/rl',
    // 征集实景图详情
    matchReaDetail: 'api/webApi/ds/ri',
    // 征集实景图投稿
    matchSubRea:'api/webApi/ds/r_sub',
    // 我投稿的大赛实景图
    myMatchRea:'api/webApi/ds/my_sub',
    // 撤回征集投稿
    delMatchRea:'api/webApi/ds/cancel',
}
export default url