import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { ElLoading } from 'element-plus';
import { getCookie } from '@/functoolbox/cookie'
import { ref } from 'vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/views/index.vue'),
    meta: { isAuth: true },
    redirect: '/login',
    children: [
      { path: 'personal', component: () => import('@/views/pages/personalCenter.vue'), meta: { isAuth: true }, name: '伊企学-个人中心' },
      { path: 'currList', component: () => import('@/views/pages/currList.vue'), meta: { isAuth: true }, name: '伊企学-精选好课' },
      { path: 'lecturer', component: () => import('@/views/pages/lecturer.vue'), meta: { isAuth: true }, name: '伊企学-讲师列表' },
      { path: 'teacherInfo', component: () => import('@/views/pages/teacherInfo.vue'), meta: { isAuth: true }, name: '伊企学-讲师详情' },
      { path: '/', component: () => import('@/views/pages/index.vue'), meta: { isAuth: true }, name: '伊企学-首页' },
      { path: 'growthCenter', component: () => import('@/views/pages/growthCenter/index.vue'), meta: { isAuth: true }, name: '伊企学-成长中心' },
      { path: 'train', component: () => import('@/views/pages/growthCenter/train.vue'), meta: { isAuth: true }, name: '伊企学-成长中心-集训营' },
      { path: 'trainMake', component: () => import('@/views/pages/growthCenter/trainMake.vue'), meta: { isAuth: true }, name: '伊企学-成长中心-集训营详情' },
      { path: 'levelInfo', component: () => import('@/views/pages/growthCenter/levelInfo.vue'), meta: { isAuth: true }, name: '伊企学-成长中心-我的等级' },
      { path: 'preInfo', component: () => import('@/views/pages/growthCenter/previewInfo.vue'), meta: { isAuth: true }, name: '伊企学-预告详情' },
      { path: 'exam', component: () => import('@/views/pages/growthCenter/examination.vue'), meta: { isAuth: true }, name: '伊企学-考试' },
      { path: 'seed', component: () => import('@/views/pages/growthCenter/seed.vue'), meta: { isAuth: true }, name: '伊企学-成长中心-课程详情' },
      { path: 'grade', component: () => import('@/views/pages/grade.vue'), meta: { isAuth: true }, name: '伊企学-成长等级' },

      { path: 'prog', component: () => import('@/views/pages/programme/index.vue'), meta: { isAuth: true }, name: '伊企学-优秀方案' },
      { path: 'progInfo', component: () => import('@/views/pages/programme/progInfo.vue'), meta: { isAuth: true }, name: '伊企学-方案中心-方案详情' },
      { path: 'realistInfo', component: () => import('@/views/pages/programme/realistInfo.vue'), meta: { isAuth: true }, name: '伊企学-方案中心-实景详情' },
      { path: 'specialInfo', component: () => import('@/views/pages/programme/specialInfo.vue'), meta: { isAuth: true }, name: '伊企学-专题详情' },
      { path: 'subprog', component: () => import('@/views/pages/programme/subProg.vue'), meta: { isAuth: true }, name: '伊企学-方案中心-方案投稿' },
      { path: 'subrea', component: () => import('@/views/pages/programme/subRea.vue'), meta: { isAuth: true }, name: '伊企学-方案中心-实景投稿' },
      { path: 'comm', component: () => import('@/views/pages/community/index.vue'), meta: { isAuth: true }, name: '伊企学-社区' },
      { path: 'commInfo', component: () => import('@/views/pages/community/commInfo.vue'), meta: { isAuth: true }, name: '伊企学-社区详情' },
      // { path: 'gif', component: () => import('@/views/pages/community/gif.vue'), meta: { isAuth: true }, name: '伊企学-预览' },
      { path: 'mall', component: () => import('@/views/pages/mall/index.vue'), meta: { isAuth: true }, name: '伊企学-商城' },

      { path: 'product', component: () => import('@/views/pages/product/index.vue'), meta: { isAuth: true }, name: '伊企学-产品中心' },
      { path: 'screenSpe', component: () => import('@/views/pages/product/screenSpe.vue'), meta: { isAuth: true }, name: '伊企学-产品专题' },
      { path: 'screenPop', component: () => import('@/views/pages/product/screenPop.vue'), meta: { isAuth: true }, name: '伊企学-热门产品' },
      { path: 'popdetail', component: () => import('@/views/pages/product/popDetails.vue'), meta: { isAuth: true }, name: '伊企学-产品详情' },
      { path: 'spedetail', component: () => import('@/views/pages/product/speDetails.vue'), meta: { isAuth: true }, name: '伊企学-专题详情' },

      { path: 'design', component: () => import('@/views/pages/design/index.vue'), meta: { isAuth: true }, name: '伊企学-设计大赛' },
      { path: 'matchDetail', component: () => import('@/views/pages/design/matchDetail.vue'), meta: { isAuth: true }, name: '伊企学-大赛详情' },
      { path: 'matchSub', component: () => import('@/views/pages/design/matchSub.vue'), meta: { isAuth: true }, name: '伊企学-大赛投稿' },
      { path: 'worksInfo', component: () => import('@/views/pages/design/worksInfo.vue'), meta: { isAuth: true }, name: '伊企学-作品详情' },
      { path: 'matchInfo', component: () => import('@/views/pages/design/matchRea.vue'), meta: { isAuth: true }, name: '伊企学-征集详情' },
      { path: 'matchsubrea', component: () => import('@/views/pages/design/matchSubRea.vue'), meta: { isAuth: true }, name: '伊企学-征集投稿' },
    ]
  },

  {
    path: '/login',
    component: () => import('@/views/login.vue'),
    name: '伊企学-账号登录'
  },
  {
    path:'/gif',
    component:()=> import('@/views/gif.vue'),
    name:'伊企学-预览'
  }

]
let loadingInstance = ref<any>(null);
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: () => ({ left: 0, top: 0 }),
})
router.beforeEach((to, from, next) => {
  let name: any = to.name
  document.title = name
  if (to.meta.isAuth && !isUserLoggedIn()) {
    next('/login')
  } else {
    if (to.name && to.name != '') {
      window.scrollTo(0, 0);
      loadingInstance = ElLoading.service({
        lock: true,
        text: '',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      });
      next()
    } else {
      sessionStorage.setItem('navIndex', '0')
      next('/')
    }

  }
})
router.afterEach(() => {
  if (loadingInstance) {
    loadingInstance.close();
  }
});
function isUserLoggedIn(): boolean {
  const Token = getCookie('token')
  if (Token) {
    return true
  } else {
    return false
  }

}

export default router
