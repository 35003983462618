import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import { VueMasonryPlugin } from 'vue-masonry';
import 'element-plus/dist/index.css'
import { createPinia } from 'pinia'
import api from './api/index'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import '@/static/font.css';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import naive from 'naive-ui'
import { ElLoading } from 'element-plus';
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
const app = createApp(App)




const pinia = createPinia()
// 全局引入 Element Plus 组件


// 创建一个全局的 loading 服务
const loadingService = ElLoading.service({
  // 你的选项
});


for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}



//挂载api层
app.config.globalProperties.$api = api;

app.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
})

app.use(VueMasonryPlugin);
app.use(router);
app.use(pinia);
app.use(ElementPlus, { locale: zhCn, });
app.use(naive)
app.mount('#app');
