<template>
  <router-view />
</template>
<script setup lang="ts">
</script>
<style lang="less">
@import url('./InitStyle/init.css');

div {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: #F5F5F5;
  min-width: 1395px;

  &:hover {
    cursor: default;
  }

  // -moz-user-select: none;
  // -o-user-select:none;
  // -khtml-user-select:none;
  // -webkit-user-select:none;
  // -ms-user-select:none;
  // user-select:none;
}



:not(body)::-webkit-scrollbar {
  width: 8px;
  height: 4px;
  // margin: inherit;
}

:not(body)::-webkit-scrollbar-thumb:hover {
  background-color: rgba(49, 52, 56, 0.856);
}
</style>
