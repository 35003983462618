// 模块化管理Url
import login from "./login/index"
import nav from './nav/index'
import indexApi from './index/index'
import comm from './comm/index'
import curr from './currLIst'
export default {
    ...login,
    ...nav,
    ...indexApi,
    ...comm,
    ...curr  
}