
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ElMessage } from 'element-plus';
import service from './services'
import router from '@/router';
import { getCookie } from '@/functoolbox/cookie'
service.interceptors.request.use(
  (config: AxiosRequestConfig): any => {
    // config.headers?.setAccept
    // 给请求头设置token
    let token = getCookie('token')
    if (token) {
      config.headers!.Authorization = `${token}`;
    }
    return config
  },
  (error: AxiosError) => {
    ElMessage.error(error.message)
    return Promise.reject(error)
  }
)
/* 响应拦截器 */
service.interceptors.response.use(
  (response: AxiosResponse) => {
    const { code, message, data } = response.data; // 根据自定义错误码判断请求是否成功
    if (code === 200) {
      // 将组件用的数据返回
      return response.data;
    }  else {
      // 处理业务错误。memessage
      console.log(message);
      if (message != '' && message != null && message != undefined) {
        ElMessage.error(message);
        return Promise.reject(new Error(message));
      }

    }
  },
  (error: AxiosError) => {
    let data: any = error.response?.data
    // 处理 HTTP 网络错误
    let message: any = "";
    // HTTP 状态码
    const status = error.response?.status;
    switch (status) {
      case 400:
        message = data.msg
        break;
      case 401:
        message = "token失效,请重新登录";
        break;
      case 400:
        message = data.msg;
        break;
      case 404:
        message = "页面不存在";
        break;
      case 500:
        message = "服务器故障";
        break;
      case 502:
        message = "数据库查询错误";
        break;
      default:
        message = "网络连接错误";
    }
    if (router.currentRoute.value.path !== "/levelInfo") {
      ElMessage({
        message,
        type:'warning',
        offset:200
      });
    }
    return Promise.reject(message)
  }
);
export default service