// 模块化管理接口请求
import login from "./login/index"
import nav from './nav/index'
import indexApi from './index/index'
import commApi from './comm/index'
import currlist from './currList'
let api = {
    loginApi: { ...login },
    navApi: { ...nav },
    indexApi: { ...indexApi },
    commApi: { ...commApi },
    currlist: { ...currlist }
};

export default api;