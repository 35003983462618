import request from '../../request/index'
import sevice from '@/sevice/index'
let api = {

    courseList: (data: any) => {
        return request.post(sevice.course, data)
    },
    teachers: (data: any) => {
        return request.post(sevice.teacher, data)
    },
    progList: () => {
        return request.get(sevice.progList)
    },

    reaList: () => {
        return request.get(sevice.reaList)
    },

    getCourseInfo: (data: any) => {
        return request.post(sevice.courseInfo, data)
    },
    getTeacherInfo: (data: any) => {
        return request.get(sevice.teacherInfo + data)
    },
    reservation: (data: any) => {
        return request.post(sevice.reservation, data)
    },
    growthBanner: () => {
        return request.get(sevice.growthBanner)
    },
    camptow: () => {
        return request.get(sevice.camptow)
    },
    camplist: (data: any) => {
        return request.post(sevice.camplist, data)
    },
    campInfo: (data: any) => {
        return request.get(sevice.campInfo + data)
    },
    siginUp: (data: any) => {
        return request.get(sevice.siginUp + data)
    },
    levelList: () => {
        return request.get(sevice.levelList)
    },
    courseLst: (currLevle: any) => {
        return request.get(sevice.courseLst + currLevle)
    },
    levelSelf: () => {
        return request.get(sevice.levelSelf)
    },
    staffInfo: () => {
        return request.get(sevice.staffInfo)
    },
    statistics: (data: any) => {
        return request.post(sevice.statistics, data)
    },
    footer: () => {
        return request.get(sevice.footer)
    },
    timeLine: (data: number = 0) => {
        return request.get(sevice.timeLine + data)
    },
    studyLst: (data: any) => {
        return request.get(sevice.studyLst + data)
    },
    examInfo: (data: any = 1) => {
        return request.get(sevice.exam + data)
    },
    submit: (data: any) => {
        return request.post(sevice.submit, data)
    },
    resetInfo: (data: any = 1) => {
        return request.get(sevice.resetInfo + data)
    },
    updatePwd: (data: any) => {
        return request.post(sevice.updatePwd, data)
    },
    real_log: (data: any) => {
        return request.post(sevice.real_log, data)
    },
    uploadImg: (data: any) => {
        return request.post(sevice.uploadImg, data)
    },
    upHead: (data: any) => {
        return request.post(sevice.upHead, data)
    },
    progTagList: () => {
        return request.get(sevice.progTagList)
    },
    programmeList: (data: any) => {
        return request.post(sevice.programmeList, data)
    },
    progBanner: () => {
        return request.get(sevice.progBanner)
    },
    progRammeInfo: (id: any) => {
        return request.get(sevice.progRammeInfo + id)
    },
    progRammeClick: (data: any) => {
        return request.get(`${sevice.progRammeClick}?id=${data.id}&type=${data.type}`)
    },
    realisticList: (data: any) => {
        return request.post(sevice.realisticList, data)
    },
    // 实景图详情
    RealisticDetail: (data: any) => {
        return request.get(sevice.realisticDetails + data)
    },
    // 实景图点赞  收藏
    realisticstatus: (data: any) => {
        return request.get(`${sevice.realisticstatus}?id=${data.id}&type=${data.type}`)
    },
    // 实景图详情  下一组
    realisticnext: (data: any) => {
        return request.get(`api/webApi/realistic/next_info?id=${data}&type=1`)
    },
    // 个人中心  收藏   方案|实景图
    collection_lst: (data: any) => {
        return request.get(`api/webApi/user/collection_lst?limit=${data.limit}&type=${data.type}&page=${data.page}`)
    },
    // 获取用户补充信息
    user_info: () => {
        return request.get(`api/webApi/user/user_info`)
    },
    // 获取用户补充信息
    create_user_info: (data: any) => {
        return request.post(`api/webApi/user/create_user_info`, data)
    },
    // 方案专题
    specialList: (data: any) => {
        return request.post(sevice.specialList, data)
    },
    // 方案详情
    specialDetails: (data: any) => {
        return request.get(sevice.specialDetails + data)
    },
    specialInfoList: (data: any) => {
        return request.post(sevice.specialInfoList, data)
    },

    commBanner: () => {
        return request.get(sevice.commBanner)
    },
    commType: () => {
        return request.get(sevice.commType)
    },
    commList: (data: any) => {
        return request.post(sevice.commList, data)
    },
    commRec: (id: any = 0) => {
        return request.get(sevice.commRec + id)
    },
    commDetails: (data: any) => {
        return request.post(sevice.commDetails, data)
    },
    commDz: (data: any) => {
        return request.post(sevice.commDz, data)
    },
    clickVideo: (data: any) => {
        return request.get(sevice.clickVideo + data)
    },
    mallList: (page: any, limit: any = 10) => {
        return request.get(`${sevice.mallList}?page=${page}&limit=${limit} `)
    },
    mallEx: (data: any) => {
        return request.post(sevice.mallEx, data)
    },
    exLog: (data: any) => {
        return request.get(sevice.exLog + data)
    },
    accountCheck: (data: any) => {
        return request.get(`${sevice.accountCheck}?searchValue=${data}`)
    },
    bindAccount: (data: any) => {
        return request.get(`${sevice.bindAccount}?id=${data}`)
    },
    getAccountInfo: () => {
        return request.get(`${sevice.getAccountInfo}`)
    },
    progContribute: (data: any) => {
        return request.get(`${sevice.progContribute}?start=${data}`)
    },
    coverList: (data: any) => {
        return request.get(`${sevice.coverList}?design_id=${data}`)
    },

    submission: (data: any) => {
        return request.post(sevice.submission, data)
    },

    subLog: (data: any) => {
        return request.get(`${sevice.subLog}?type=${data.type}&page=${data.page}`)
    },

    withSub: (id: any) => {
        return request.get(`${sevice.withSub}?id=${id}`)
    },

    againSub: (id: any) => {
        return request.get(`${sevice.againSub}?id=${id}`)
    },

    checkSubScore: (id: any) => {
        return request.get(`${sevice.checkSubScore}?id=${id}`)
    },

    myProg: (page: any) => {
        return request.get(`${sevice.myProg}?page=${page}`)
    },

    myRea: (page: any) => {
        return request.get(`${sevice.myRea}?page=${page}`)
    },

    getVideoToken: (data: any) => {
        return request.post(sevice.getVideoToken, data)
    },

    refreshUploadVideo: (id: any) => {
        return request.get(`${sevice.refreshUploadVideo}?videoId=${id}`)
    },

    getVideoInfo: (id: any) => {
        return request.get(`${sevice.getVideoInfo}?id=${id}`)
    },
    delVideo: (id: any) => {
        return request.get(`${sevice.delVideo}?video_id=${id}`)
    },
    realisticSub: (data: any) => {
        return request.post(sevice.realisticSub, data)
    },

    reaSubLog: (data: any) => {
        return request.get(`${sevice.reaSubLog}?page=${data.page}&type=${data.type}`)
    },

    delRea: (id: any) => {
        return request.get(`${sevice.delRea}?id=${id}`)
    },

    searchComm: (id: any) => {
        return request.get(`${sevice.searchComm}?id=${id}`)
    },

    subProgInfo: (id: any) => {
        return request.get(`${sevice.subProgInfo}?id=${id}`)
    },

    updaSubProg: (data: any) => {
        return request.post(sevice.updaSubProg, data)
    },

    progPl: (data: any) => {
        return request.post(sevice.progPl, data)
    },

    delProgPl: (id: any) => {
        return request.get(`${sevice.delProgPl}?id=${id}`)
    },

    reaPl: (data: any) => {
        return request.post(sevice.reaPl, data)
    },

    progPlList: (data: any) => {
        return request.get(`${sevice.progPlList}?page=${data.page}&id=${data.id}`)
    },

    reaPlList: (data: any) => {
        return request.get(`${sevice.reaPlList}?page=${data.page}&id=${data.id}`)
    },

    delReaPl: (id: any) => {
        return request.get(`${sevice.delReaPl}?id=${id}`)
    },

    currPlList: (data: any) => {
        return request.get(`${sevice.currPlList}?page=${data.page}&id=${data.id}`)
    },

    currPl: (data: any) => {
        return request.post(sevice.currPl, data)
    },

    delCurrPl: (id: any) => {
        return request.get(`${sevice.delCurrPl}?id=${id}`)
    },

    noticePlList: (data: any) => {
        return request.get(`${sevice.noticePlList}?page=${data.page}&id=${data.id}`)
    },

    noticePl: (data: any) => {
        return request.post(sevice.noticePl, data)
    },

    delNoticePl: (id: any) => {
        return request.get(`${sevice.delNoticePl}?id=${id}`)
    },

    progNotice: () => {
        return request.get(`${sevice.progNotice}`)
    },
    realisticaImg: () => {
        return request.get(`api/webApi/sub/subRe`)
    },
    checkAcc: () => {
        return request.get(sevice.checkAcc)
    },
    proLabel: () => {
        return request.get(sevice.proLabel)
    },
    heatProduct: () => {
        return request.get(sevice.heatProduct)
    },
    proDetail: (id: any) => {
        return request.get(`${sevice.proDetail}/${id}`)
    },
    proTopic: () => {
        return request.get(sevice.proTopic)
    },
    proTopicDetail: (id: any) => {
        return request.get(`${sevice.proTopicDetail}/${id}`)
    },
    proTopicFilter: (data: any) => {
        return request.post(sevice.proTopicFilter, data)
    },
    proFilter: (data: any) => {
        return request.post(sevice.proFilter, data)
    },
    designMatch: (page: any) => {
        return request.get(`${sevice.designMatch}?page=${page}`)
    },
    matchInfo: (id: any) => {
        return request.get(`${sevice.matchInfo}${id}`)
    },
    matchSub: (data: any) => {
        return request.post(sevice.matchSub, data)
    },
    myWorks: (id: any) => {
        return request.get(`${sevice.myWorks}?design_id=${id}`)
    },
    allworks: (data: any) => {
        return request.get(`${sevice.allworks}?page=${data.page}&design_id=${data.id}$order=${data.order}`)
    },
    worksDetail: (id: any) => {
        return request.get(`${sevice.worksDetail}/?id=${id}`)
    },
    updateWork: (data: any) => {
        return request.post(sevice.updateWork, data)
    },
    awardWorks: (id: any) => {
        return request.get(`${sevice.awardWorks}?comp_id=${id}`)
    },
    matchVote: (id: any) => {
        return request.get(`${sevice.matchVote}?id=${id}`)
    },
    worksInfo: (id: any) => {
        return request.get(`${sevice.worksInfo}?id=${id}`)
    },
    matchPlList: (data: any) => {
        return request.get(`${sevice.matchPlList}?page=${data.page}&id=${data.id}`)
    },
    matchPl: (data: any) => {
        return request.post(sevice.matchPl, data)
    },
    delMatchPl: (id: any) => {
        return request.get(`${sevice.delMatchPl}?id=${id}`)
    },
    matchReaList: (page: any) => {
        return request.get(`${sevice.matchReaList}?page=${page}`)
    },
    matchReaDetail: (id: any) => {
        return request.get(`${sevice.matchReaDetail}/${id}`)
    },
    matchSubRea: (data: any) => {
        return request.post(sevice.matchSubRea, data)
    },
    myMatchRea: (id: any) => {
        return request.get(`${sevice.myMatchRea}?id=${id}`)
    },
    delMatchRea: (data: any) => {
        return request.get(`${sevice.delMatchRea}?id=${data.id}&c_id=${data.c_id}`)
    }
}
export default api 