// 首页接口Url
interface urlType {
    treeUrl: string,
    loginConfig:string
}
const url: urlType = {
    // login
    treeUrl: 'api/webApi/login',
    // 登录页配置
    loginConfig:'api/webApi/loginConfig'
}
export default url
