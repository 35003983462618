// 首页接口集合
import request from '../../request/index'
import sevice from '@/sevice/index'
let api = {
    login: (data:any) => {
        return request.post(sevice.treeUrl,data)
    },
    loginConfig:()=>{
        return request.get(sevice.loginConfig)
    }
}
export default  api 