interface urlType {
    banner: string,
    temps:string
}
const url: urlType = {
    // 首页banner
    banner:'api/webApi/index/banner?banner_type=',
    // 首页模块
    temps:'api/webApi/index/indexTitle'
}
export default url